import { Image, Progress } from "@heroui/react";
import { FocusScope } from "@react-aria/focus";
import clsx from "clsx";
import { APP_CLINIC, DOCUMENT_PRIVACY_POLICY } from "core/consts";
import { getStaticAsset } from "core/model/config";
import { isDemo } from "core/model/utils/featureFlags";
import { Link } from "ds/ui";
import { useExternalLinkLabel } from "ds_legacy/components/LinkV2";
import { getRecareLogoOnlyPath } from "ds_legacy/components/RecareLogo";
import { useLegalDocuments } from "dsl/atoms/LegalDocuments";
import {
  loginPage,
  LoginPageVariants,
} from "dsl/ecosystems/LoginPageV2/classes";
import { useTranslations } from "translations";

export type LoginLoadingPageProps = LoginPageVariants;

export function LoginLoadingPage({
  app = APP_CLINIC,
  env,
}: LoginLoadingPageProps) {
  const classes = loginPage({ app, env });
  const translations = useTranslations();
  const { getDocumentUrl } = useLegalDocuments();
  const externalLinkLabel = useExternalLinkLabel();

  return (
    <div className={classes.page()}>
      <div className={clsx(classes.container(), "min-h-fit")}>
        <div className={classes.content()}>
          <div className={clsx(classes.header(), "h-16 self-center")}>
            <Image
              alt="Recare logo"
              src={getStaticAsset(getRecareLogoOnlyPath("master"))}
              width={64}
              height={64}
            />
          </div>
          <h1 className={clsx(classes.title(), "text-center font-semibold")}>
            {isDemo && (
              <span className="font-normal">
                {translations.login.loginPage.titleDemo} |{" "}
              </span>
            )}
            {translations.login.loading.settingEnvironment}
          </h1>
          <FocusScope autoFocus>
            <Progress
              aria-label={translations.general.loading}
              aria-live="polite"
              isIndeterminate
              size="sm"
              tabIndex={-1}
            />
          </FocusScope>
          <div className="flex justify-center">
            <p className="w-4/5 text-center text-sm font-light">
              {translations.login.loading.dontLeavePage}
            </p>
          </div>
        </div>
        <div className={classes.footer()}>
          <p>
            {translations.login.loginPage.privacyPolicyInfo}{" "}
            <Link
              id="privacy-policy"
              aria-label={externalLinkLabel(
                translations.login.privacyPolicy,
                "pdf",
              )}
              color="primary-dark"
              href={getDocumentUrl(DOCUMENT_PRIVACY_POLICY)}
              size="sm"
              target="_blank"
            >
              {translations.login.privacyPolicy}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
