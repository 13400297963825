import { TRACK_EVENTS } from "core/consts";
import { CareseekerConfig } from "core/types";
import { customAlphabet } from "nanoid";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTracking } from "react-tracking";

const alphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const nanoid = customAlphabet(alphabet, 8);
const TRACK_ID = nanoid();
const REFRESH_INTERVAL_MS = 2 * 60 * 1000; // 2 minutes

const HL7ImportStatusContext = React.createContext<{
  gateway_version: string | undefined;
  hasOneWaySync: boolean;
  hasTwoWaySync: boolean;
  isHISActivated: boolean;
  on_premise_authorization_token: string | undefined;
  on_premise_domain_ssl: string | undefined;
  sslConnected: boolean | undefined;
}>({
  gateway_version: undefined,
  hasOneWaySync: false,
  hasTwoWaySync: false,
  isHISActivated: false,
  on_premise_authorization_token: undefined,
  on_premise_domain_ssl: undefined,
  sslConnected: undefined,
});

export const useHL7ImportStatusContext = () =>
  React.useContext(HL7ImportStatusContext);

export function HL7ImportStatusContextProvider({
  children,
  config,
}: {
  children: React.ReactNode;
  config: CareseekerConfig | undefined;
}) {
  const { trackEvent } = useTracking();
  const intervalRef = useRef<number | undefined>();

  const {
    activate_gateway_debug_mode,
    activate_ssl_hl7_connector,
    on_premise_authorization_token,
    on_premise_domain_ssl,
    on_premise_file_sync,
  } = config ?? {};

  const [sslConnectionStatus, setSslConnected] = useState<
    { connected: boolean; version?: string } | undefined
  >();

  useEffect(() => {
    let abortController: AbortController | undefined;

    function testSSL() {
      if (!on_premise_domain_ssl) {
        return;
      }

      abortController = new AbortController();
      const signal = abortController.signal;

      fetch(`https://${on_premise_domain_ssl}/ping`, { signal })
        .then((response) => {
          setSslConnected({
            connected: response.status === 200,
            version: response.headers.get("hl7-connector-version") || undefined,
          });
          // Track successful ping
          trackEvent({
            name: TRACK_EVENTS.SSL_IMPORT_PING_TEST,
            status: response.status,
            uid: TRACK_ID,
          });
        })
        .catch((err) => {
          if (err.name === "AbortError") return;
          setSslConnected({ connected: false });
          // Track error details
          trackEvent({
            name: TRACK_EVENTS.SSL_IMPORT_PING_TEST,
            status: -1,
            uid: TRACK_ID,
            error_details: typeof err === "string" ? err : JSON.stringify(err),
          });
        });
    }

    if (activate_ssl_hl7_connector && on_premise_domain_ssl) {
      testSSL();
      // Refresh every 2 minutes
      intervalRef.current = window.setInterval(() => {
        testSSL();
      }, REFRESH_INTERVAL_MS);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (abortController) {
        abortController.abort();
      }
    };
  }, [activate_ssl_hl7_connector, on_premise_domain_ssl, trackEvent]);

  const importStatusValue = useMemo(() => {
    // isHISActivated: Determines whether the Hospital Information System (HIS) integration is active.
    // This is true if there is an SSL HL7 connector and the gateway is not in debug mode.
    const isHISActivated =
      !!activate_ssl_hl7_connector && !activate_gateway_debug_mode;

    const sslConnected = !!sslConnectionStatus?.connected;

    // hasOneWaySync indicates that one-way synchronization is active.
    // One-way sync is enabled when:
    // - The Hospital Information System (HIS) integration is activated, and
    // - A successful SSL connection to the on-premise HL7 server is established.
    const hasOneWaySync = isHISActivated && sslConnected;

    // Two-way sync means that in addition to one-way sync,
    // the system is configured to send files/messages back to the on-prem gateway.
    const hasTwoWaySync = hasOneWaySync && !!on_premise_file_sync;

    return {
      gateway_version: sslConnectionStatus?.version,
      hasOneWaySync,
      hasTwoWaySync,
      isHISActivated,
      on_premise_authorization_token,
      on_premise_domain_ssl,
      sslConnected,
    };
  }, [
    activate_gateway_debug_mode,
    activate_ssl_hl7_connector,
    on_premise_authorization_token,
    on_premise_domain_ssl,
    on_premise_file_sync,
    sslConnectionStatus,
  ]);

  return (
    <HL7ImportStatusContext.Provider value={importStatusValue}>
      {children}
    </HL7ImportStatusContext.Provider>
  );
}
