import { SEARCH_TYPE_CARE, SEARCH_TYPE_REHABILITATION } from "core/consts";
import {
  getAge,
  getAgeIntervalFromRangeStr,
  getWeightIntervalFromRangeStr,
  getWeightIntervalString,
} from "core/model/patients";
import { Auction, EncryptedField } from "core/types";
import cloneDeep from "lodash/cloneDeep";

function getEncryptedField(field: EncryptedField | string | null | undefined) {
  if (typeof field === "string") return field;
  return field?.decrypted;
}

export function handleAgeInterval(ageInterval: ToType) {
  if (typeof ageInterval === "string")
    return getAgeIntervalFromRangeStr(ageInterval);
  if (ageInterval?.value != null)
    return getAgeIntervalFromRangeStr(ageInterval.value);
  return ageInterval;
}

export type FieldsHolderType = {
  age: ToType;
  age_interval: ToType;
  base_care: ToType;
  birth_date: ToType;
  care_duration_in_days: ToType;
  careseeker_id: ToType;
  closed_area_needed: ToType;
  companionship: ToType;
  compression: ToType;
  country_wide_search: ToType;
  dialysis: ToType;
  direct_transfer_necessary: ToType;
  incontinence: ToType;
  injection: ToType;
  insurance: ToType;
  interested_long_term_stay: ToType;
  isElective?: boolean;
  latitude: ToType;
  longitude: ToType;
  medication: ToType;
  nutrition: ToType;
  oxygen_therapy: ToType;
  palliative: ToType;
  palliativeCertificate: ToType;
  protected_area_needed: ToType;
  psychiatric_care: ToType;
  public_pension: ToType;
  radius_in_km: ToType;
  search_type: ToType;
  selected_payment_method: ToType;
  solutions: ToType;
  specialised_intensive_nurse: ToType;
  specialization: ToType;
  specializations: ToType;
  start_date: ToType;
  start_date_flexible: ToType;
  tracheostomy: ToType;
  weight_interval: ToType;
  wound_care: ToType;
  zipcode: ToType;
};

export const fieldsHolder: FieldsHolderType = {
  country_wide_search: null,
  direct_transfer_necessary: null,
  interested_long_term_stay: null,
  specialization: null,
  search_type: null,
  solutions: null,
  specializations: null,
  careseeker_id: null,
  start_date: null,
  start_date_flexible: null,
  care_duration_in_days: null,
  age: null,
  age_interval: null,
  birth_date: null,
  zipcode: null,
  latitude: null,
  longitude: null,
  radius_in_km: null,
  palliative: false,
  palliativeCertificate: false,
  tracheostomy: false,
  specialised_intensive_nurse: false,
  base_care: false,
  companionship: false,
  closed_area_needed: false,
  nutrition: null,
  incontinence: null,
  oxygen_therapy: null,
  dialysis: null,
  wound_care: null,
  psychiatric_care: null,
  medication: null,
  compression: null,
  injection: null,
  selected_payment_method: null,
  insurance: null,
  public_pension: null,
  weight_interval: null,
  protected_area_needed: false,
} as const;

export function extractPatientTypeOfTransportFields(
  auction: Auction | undefined,
): FieldsHolderType {
  const fields = { ...fieldsHolder };
  fields.solutions = auction?.solutions;
  fields.specializations = auction?.specializations;
  return fields;
}

function normalizeSelect(specializations: ToType) {
  if (Array.isArray(specializations)) {
    return specializations.map((s: any) => (s.value ? s.value : s));
  }
  if (typeof specializations === "object")
    return specializations?.value
      ? Array.isArray(specializations.value)
        ? specializations.value.map((s: any) => s.value)
        : [specializations.value]
      : null;
  return null;
}

export function extractPatientFields(
  auction: Auction,
  excluded: Array<string> = [],
  options: AnyObject = { distanceForZipCode: false },
): FieldsHolderType {
  const patient = auction.patient;
  const fields = { ...fieldsHolder };

  fields.search_type = auction?.search_type;

  if (!excluded?.includes("solutions")) {
    fields.solutions = auction?.solutions;
  }

  if (!excluded?.includes("specializations")) {
    fields.specializations = auction?.specializations;
  }

  if (fields.search_type === SEARCH_TYPE_REHABILITATION) {
    fields.selected_payment_method = auction?.payers?.selected_payment_method;
    fields.insurance = auction?.payers?.insurance;
  }

  fields.start_date = auction?.start_date;
  fields.start_date_flexible = auction?.start_date_flexible;

  if (fields.search_type === SEARCH_TYPE_CARE) {
    fields.care_duration_in_days = patient.profile?.care_duration_in_days;
    fields.interested_long_term_stay =
      patient.profile?.interested_long_term_stay;
  }

  fields.age_interval = getAge(
    patient.profile?.age_interval,
    fields.search_type,
  );

  fields.age =
    patient.profile?.age_interval &&
    (patient.profile.age_interval.min || patient.profile.age_interval.max);

  fields.birth_date =
    patient?.profile && getEncryptedField(patient.profile.birth_date);

  fields.zipcode =
    auction?.profile?.search_location &&
    `${auction?.profile.search_location.zipcode}${
      options.distanceForZipCode ? ` (${options.distanceForZipCode})` : ""
    }`;

  fields.latitude = auction?.profile?.search_location?.latitude;
  fields.longitude = auction?.profile?.search_location?.longitude;

  fields.country_wide_search = auction?.country_wide_search;

  fields.radius_in_km = auction?.radius_in_meter
    ? auction?.radius_in_meter / 1000
    : undefined;

  fields.palliative = patient.care_needs?.palliative != null || false;
  fields.palliativeCertificate =
    patient.care_needs?.palliative?.palliative_certificate_needed || false;
  fields.tracheostomy =
    patient.care_needs?.breathing?.tracheostomy != null || false;
  fields.specialised_intensive_nurse =
    patient.care_needs?.breathing?.specialised_intensive_nurse || false;

  fields.base_care = patient.care_needs?.base_care != null || false;
  fields.companionship = patient.care_needs?.companionship != null || false;

  fields.careseeker_id = patient.careseeker?.id;

  fields.nutrition = patient.care_needs?.nutrition != null || false;
  fields.incontinence = patient.care_needs?.incontinence != null || false;
  fields.oxygen_therapy =
    patient.care_needs?.breathing?.oxygen_therapy != null || false;
  fields.dialysis = patient.care_needs?.dialysis != null || false;
  fields.wound_care = patient.care_needs?.wound_care != null || false;
  fields.psychiatric_care =
    patient.care_needs?.psychiatric_care != null || false;
  fields.medication = patient.care_needs?.medication != null || false;
  fields.compression = patient.care_needs?.compression != null || false;
  fields.injection = patient.care_needs?.injection != null || false;
  fields.closed_area_needed =
    patient.diagnosis?.mental_health?.run_away_description != null || false;
  fields.direct_transfer_necessary = patient.profile?.direct_transfer_necessary;
  fields.isElective = auction.profile?.is_elective;
  fields.weight_interval = getWeightIntervalString(
    patient.profile?.weight_interval,
  );
  fields.protected_area_needed =
    patient.diagnosis?.mental_health?.protected_area_needed != null || false;
  return fields;
}

export function applyFormValueFields(fields: FieldsHolderType, value: ToType) {
  const ret = cloneDeep(fields);

  if (value.solutions !== undefined) {
    ret.solutions = value.solutions;
  }

  if (value.selected_payment_method !== undefined) {
    ret.selected_payment_method = value.selected_payment_method;
    if (value?.payers_insurance?.value !== null) {
      ret.insurance = {
        id: value?.payers_insurance?.value,
        name: value?.payers_insurance?.label,
      };
    }
    if (value?.public_pension?.value !== null) {
      ret.public_pension = {
        id: value?.public_pension?.value,
        name: value?.public_pension?.label,
      };
    }
  }

  if (value.start_date !== undefined) {
    ret.start_date = value.start_date;
  }

  if (value.age !== undefined) {
    ret.age = value.age;
  }

  if (value.birth_date !== undefined) {
    ret.birth_date = getEncryptedField(value.birth_date);
  }

  if (value.age_interval !== undefined) {
    ret.age_interval = value.age_interval;
  }

  if (value.specializations !== undefined)
    ret.specializations = normalizeSelect(value.specializations);

  if (value.location?.zipcode !== undefined) {
    ret.zipcode = value.location.zipcode;
  }

  if (value.location?.coordinates) {
    if (value.location.coordinates.latitude !== undefined) {
      ret.latitude = value.location.coordinates.latitude;
    }
    if (value.location.coordinates.longitude !== undefined) {
      ret.longitude = value.location.coordinates.longitude;
    }
  }

  if (value.radius_in_km) {
    ret.radius_in_km = value.radius_in_km;
  }

  ret.country_wide_search = value.country_wide_search;

  if (value._palliative != null) {
    ret.palliative = value._palliative;
    ret.palliativeCertificate =
      (value._palliative && value.palliative_certificate_needed) || false;
  }

  if (value.run_away_description !== undefined) {
    ret.closed_area_needed = value.run_away_description != null;
  }

  if (value.protected_area_needed !== undefined) {
    ret.protected_area_needed = value.protected_area_needed != null;
  }

  if (value._breathing != null) {
    ret.tracheostomy = value._breathing && value.tracheostomy != null;
    ret.specialised_intensive_nurse =
      value._breathing && value.specialised_intensive_nurse && true;
    ret.oxygen_therapy = value._breathing && value.oxygen_therapy != null;
  }

  if (value._base_care != null) {
    ret.base_care = value._base_care;
  }

  if (value._companionship != null) {
    ret.companionship = value._companionship;
  }

  if (value._nutrition != null) {
    ret.nutrition = value._nutrition;
  }

  if (value._incontinence != null) {
    ret.incontinence = value._incontinence;
  }

  if (value._dialysis != null) {
    ret.dialysis = value._dialysis;
  }
  if (value._wound_care != null) {
    ret.wound_care = value._wound_care;
  }
  if (value._psychiatric_care != null) {
    ret.psychiatric_care = value._psychiatric_care;
  }
  if (value._medication != null) {
    ret.medication = value._medication;
  }
  if (value._compression != null) {
    ret.compression = value._compression;
  }
  if (value._injection != null) {
    ret.injection = value._injection;
  }
  if (value.weight != null) {
    ret.weight_interval = getWeightIntervalString(value.weight);
  }

  return ret;
}

export function getAuctionFromFields(fields: FieldsHolderType): Auction {
  return {
    id: 1,
    profile: {
      search_location: {
        zipcode: fields.zipcode,
        latitude: fields.latitude,
        longitude: fields.longitude,
      },
    },
    start_date: fields.start_date,
    start_date_flexible: fields.start_date_flexible,
    country_wide_search: fields.country_wide_search,
    radius_in_meter: fields.radius_in_km
      ? fields.radius_in_km * 1000
      : undefined,
    solutions: fields.solutions,
    specializations: normalizeSelect(fields.specializations),
    search_type: fields.search_type,
    payers: {
      selected_payment_method: fields.selected_payment_method || undefined,
      insurance: fields.insurance,
      public_pension: fields.public_pension || undefined,
    },
    patient: {
      id: 1,
      version: "2",
      // @ts-ignore
      patient_type: fields.search_type, // not to break until the backend only relies on search type
      careseeker: { __typename: "Careseeker", id: fields.careseeker_id },
      diagnosis: {
        mental_health:
          fields.closed_area_needed || fields.protected_area_needed
            ? {
                run_away_description: fields.closed_area_needed
                  ? ""
                  : undefined,
                protected_area_needed: fields.protected_area_needed
                  ? ""
                  : undefined,
              }
            : undefined,
      },
      profile: {
        __typename: "PatientProfile",
        age: fields.age || null,
        age_interval: handleAgeInterval(fields.age_interval),
        care_duration_in_days: fields.care_duration_in_days,
        weight_interval: getWeightIntervalFromRangeStr(fields.weight_interval),
      },
      care_needs: {
        palliative: fields.palliative
          ? Object.assign(
              {
                description: "",
              },
              fields.palliativeCertificate
                ? {
                    palliative_certificate_needed: true,
                  }
                : undefined,
            )
          : undefined,
        breathing:
          fields.tracheostomy ||
          fields.oxygen_therapy ||
          fields.specialised_intensive_nurse
            ? {
                oxygen_therapy: fields.oxygen_therapy ? "" : undefined,
                tracheostomy: fields.tracheostomy ? "" : undefined,
                specialised_intensive_nurse: fields.specialised_intensive_nurse
                  ? true
                  : undefined,
              }
            : undefined,
        base_care: fields.base_care ? {} : undefined,
        companionship: fields.companionship ? {} : undefined,
        nutrition: fields.nutrition ? {} : undefined,
        incontinence: fields.incontinence ? {} : undefined,
        dialysis: fields.dialysis ? {} : undefined,
        wound_care: fields.wound_care ? {} : undefined,
        psychiatric_care: fields.psychiatric_care ? {} : undefined,
        medication: fields.medication ? {} : undefined,
        compression: fields.compression ? {} : undefined,
        injection: fields.injection ? {} : undefined,
      },
    },
  };
}
