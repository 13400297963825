import {
  APP_CAREPROVIDER,
  APP_CLINIC,
  APP_PROVIDER_SEARCH,
  BLUR_ALL,
  BLUR_LAST,
  BLUR_NONE,
  ORIGIN_RECEIVER,
  ORIGIN_SENDER,
  ORIGIN_TOOLS,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_REHABILITATION,
} from "core/consts";
import { EVENTS } from "core/consts/events";
import { useDateLocale } from "core/hooks/useDateLocale";
import { getApp } from "core/model/config";
import { UNDO_ACTION_MAPPING, getUndoActionType } from "core/model/requests";
import { formatUnixDate } from "core/model/utils/dates";
import {
  useGetOntology,
  useGetTranslationForSearchType,
} from "core/model/utils/ontologies/hooks";
import {
  AcceptedSolution,
  Account,
  Auction,
  AuctionRequest,
  Event,
  EventLocation,
  EventType,
  GetOntologyType,
  Origin,
  RequestActions,
} from "core/types";
import { EventSpecMessage, getEventSpecs } from "dsl/atoms/EventSpecs";
import { CardFormat, EventCardProps } from "dsl/molecules/Messenger/EventCard";
import { useEffect, useRef, useState } from "react";
import {
  useLoggedCareprovider,
  useLoggedCareseeker,
  useLoggedInAccount,
} from "reduxentities/hooks";
import {
  Locale as LocaleString,
  useLocale,
  useTranslations,
} from "translations";
import Translations from "translations/types";

export type EventSpecsEnhanced = Omit<
  EventCardProps,
  | "cancelAction"
  | "onSubmitQueryProgress"
  | "print"
  | "isDividerVisible"
  | "shouldShowBefore"
  | "patientId"
> & {
  id: number | undefined;
  isNewLastEvent: boolean;
};

export function whoTookAction(
  accountId: Account["id"] | null | undefined,
  event: Event,
  accountName: string,
  forIsik: boolean,
): string | null | undefined {
  if (event.account?.id && event.account.id === accountId && !forIsik) {
    return null;
  } else if (accountName) {
    return accountName;
  }

  return null;
}

export function whoIsSender(
  accountId: Account["id"] | null | undefined,
  event: Event,
  translations: Translations,
): string | null | undefined {
  if (accountId && event.account && event.account.id === accountId) return null;
  switch (event.origin) {
    // todo: add origin provider search
    case ORIGIN_RECEIVER:
      switch (event.context?.patient_type) {
        case SEARCH_TYPE_CARE:
          return translations.messenger.sender.provider;
        case SEARCH_TYPE_HOSPITAL:
          return translations.messenger.sender.geriatrics;
        case SEARCH_TYPE_REHABILITATION:
          return translations.messenger.sender.rehabClinic;
        default:
          return null;
      }
    case ORIGIN_SENDER:
      return translations.messenger.sender.clinic;
    default:
      return null;
  }
}

// true is right; false is left side of the messenger
export function checkIsEventInitiatedByUser({
  origin,
  type,
}: {
  origin: Origin | undefined;
  type: EventType | undefined;
}): boolean {
  const app = getApp();

  switch (origin) {
    case ORIGIN_RECEIVER:
      return app == APP_CAREPROVIDER;
    case ORIGIN_SENDER:
      return (
        // todo: add provider search as origin
        app != null &&
        ([APP_CLINIC, APP_PROVIDER_SEARCH] as number[]).includes(app)
      );
    case ORIGIN_TOOLS:
      return false;
    default:
      console.error(`missing origin for event: ${type}`);
      return false;
  }
}

export function isEncrypted(event: Event) {
  return (
    ((event.context?.message_iv != null ||
      event.context?.seald_message != null) &&
      !event.context.decrypted) ||
    false
  );
}

export function getMessage(
  event: Event,
  encrypted: boolean,
  translations: Translations,
) {
  if (encrypted) return translations.auctionRequest.encryptedMessage;
  return event?.context?.message;
}

function handleAcceptedDateMessages({
  context,
  locale,
  translations,
}: {
  context?: {
    arrival_time?: string;
    possible_entry_date?: number;
  };
  locale: LocaleString;
  translations: Translations;
}) {
  const entryDate = context?.possible_entry_date;
  const date = entryDate && formatUnixDate(entryDate, locale);
  const entryTime = context?.arrival_time;

  return {
    date: date
      ? `${translations.auctionResponse.possibleEntryDateInfo} ${date}`
      : undefined,
    time:
      date && entryTime
        ? `${translations.auctionRequest.arrivalTime}: ${entryTime}`
        : undefined,
  };
}

export function getLegacyAcceptedMessages({
  encrypted,
  event,
  locale,
  translations,
}: {
  encrypted: boolean;
  event: Event;
  locale: LocaleString;
  translations: Translations;
}) {
  const message = getMessage(event, encrypted, translations);
  const eventMessage = handleAcceptedDateMessages({
    context: event?.context,
    locale,
    translations,
  });

  return {
    ...(message ? { messages: [message] } : {}),
    ...(eventMessage
      ? { eventMessages: [{ title: "", ...eventMessage }] }
      : {}),
  };
}

export function getAcceptedMessages({
  encrypted,
  event,
  getOntology,
  locale,
  translations,
}: {
  encrypted: boolean;
  event: Event;
  getOntology: GetOntologyType;
  locale: LocaleString;
  translations: Translations;
}) {
  const eventMessages = event.context?.accepted_solutions?.reduce<
    EventSpecMessage[]
  >((solutionMessages, acceptedSolution: AcceptedSolution) => {
    if (!acceptedSolution.solution) {
      console.error("no solution in accepted solution for event: ", event.id);
    }

    const eventMessage = {
      title: getOntology({
        type: "solution",
        key: acceptedSolution.solution,
      }),
      ...handleAcceptedDateMessages({
        context: acceptedSolution,
        locale,
        translations,
      }),
    };

    if (eventMessage.title) {
      return [...solutionMessages, eventMessage];
    }
    return solutionMessages;
  }, []);

  const message = getMessage(event, encrypted, translations);
  return {
    ...(message && { messages: [message] }),
    ...(eventMessages?.length && { eventMessages }),
  };
}

export const getCancelAction = ({
  eventType,
  onCancel,
  requestActions,
}: {
  eventType: number | undefined;
  onCancel?: (eventType: string) => void;
  requestActions?: RequestActions;
}): (() => void) | null => {
  const undoActionType = getUndoActionType({ requestActions });

  if (!undoActionType) return null;

  const { cancelType, requestActionEvent } =
    UNDO_ACTION_MAPPING[undoActionType] || {};

  const isUndoableEvent = requestActionEvent === eventType;

  if (!isUndoableEvent || onCancel == null || !cancelType) return null;

  return () => onCancel(cancelType);
};

export const getBlurType = ({
  eventType,
  shouldBlur,
}: {
  eventType: number | undefined;
  shouldBlur: boolean | undefined;
}) => {
  if (shouldBlur) {
    if (
      (
        [EVENTS.REQUEST_ACCEPTED, EVENTS.RESPONSE_APPROVED] as number[]
      ).includes(eventType || -1)
    ) {
      // Encrypted data is only found in the last bubble for accepted event
      return BLUR_LAST;
    } else {
      return BLUR_ALL;
    }
  }
  return BLUR_NONE;
};

export function useGetEventsSpecs({
  auction,
  auctionRequest,
  eventLocation,
  events,
  forCareprovider,
}: {
  auction: Auction | null | undefined;
  auctionRequest?: AuctionRequest;
  eventLocation: EventLocation;
  events: Event[] | undefined;
  forCareprovider?: boolean;
}) {
  const patientId = auction?.patient?.user_id;
  const loggedCareseeker = useLoggedCareseeker();
  const loggedCareprovider = useLoggedCareprovider();
  const account = useLoggedInAccount();
  const translations = useTranslations();
  const getOntology = useGetOntology();
  const { currentLocale } = useDateLocale();
  const locale = useLocale();
  const getTranslationForSearchType = useGetTranslationForSearchType();
  const [eventSpecs, setEventSpecs] = useState<EventSpecsEnhanced[]>([]);
  const previousMessagesLengthRef = useRef<number | null>(null);

  useEffect(() => {
    if (events) {
      const eventSpecs = events.reduce<EventSpecsEnhanced[]>(
        (eventSpecs, event, eventIndex, eventArray) => {
          const allContactedTranslation = getTranslationForSearchType({
            translationKey: "search.allContacted",
            receiverType: "receiverTypePlural",
            searchType: event.context?.patient_type,
          });
          const auctionSuccessUnknownTranslation = getTranslationForSearchType({
            translationKey: "timeline.auctionSuccessUnknown",
            receiverType: "receiverTypeSingular",
            searchType: event.context?.patient_type,
          });
          const notNeededTranslation = getTranslationForSearchType({
            translationKey: "patient.stopAuction.notNeeded",
            receiverType: "receiverTypeSingular",
            searchType: event.context?.patient_type,
          });

          const specs = getEventSpecs({
            accountId: account?.id,
            allContactedTranslation,
            auctionRequest,
            auctionSuccessUnknownTranslation,
            currentLocale,
            loggedCareseekerId: loggedCareseeker?.id,
            loggedCareproviderId: loggedCareprovider?.id,
            event,
            getOntology,
            locale,
            location: eventLocation,
            notNeededTranslation,
            patientId,
            sharePatientDataAutomatically:
              auction?.share_patient_data_automatically,
            translations,
          });

          if (!specs) {
            return eventSpecs;
          }

          const cardFormat: CardFormat = {
            blurType: getBlurType({
              shouldBlur: isEncrypted(event) || specs.shouldBlur,
              eventType: event.type,
            }),
            variant: specs?.variant,
            lastSeens: event.last_seens,
          };

          const isEventUserInitiated = checkIsEventInitiatedByUser({
            origin: event.origin,
            type: event.type,
          });

          const isNewMessage =
            previousMessagesLengthRef.current !== null &&
            eventArray.length > previousMessagesLengthRef.current;
          const isLastEvent = eventIndex === eventArray.length - 1;
          const isNewLastEvent = isLastEvent && isNewMessage;

          const eventSpecsEnhanced: EventSpecsEnhanced = {
            cardFormat,
            eventMessages: specs.eventMessages,
            file: event.context?.file,
            Icon: (forCareprovider && specs.recieverIcon) || specs.icon,
            id: event.id,
            isEventUserInitiated,
            isNewLastEvent,
            messages: specs.message ? [specs.message] : specs.messages || null,
            searchType: event.context?.patient_type,
            sentByLabel: specs.sentByLabel,
            statusHeader:
              (forCareprovider && specs.receiverTitle) || specs.title,
            timestamp: event.timestamp,
            type: event.type,
          };

          return [...eventSpecs, eventSpecsEnhanced];
        },
        [],
      );
      previousMessagesLengthRef.current = eventSpecs.length;
      setEventSpecs(eventSpecs);
    }
  }, [events]);

  return eventSpecs;
}
