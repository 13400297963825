import { defaultsDeep } from "lodash";
import { Careseeker, DeepNullableObj } from "../types";
import { MockFn, createMock } from "./index";
import mockCareseekerModule from "./mockCareseekerModule";
import mockEvent from "./mockEvent";

const mockCareseeker: MockFn<Partial<DeepNullableObj<Careseeker>>> = (args) =>
  defaultsDeep(
    args,
    createMock(
      "Careseeker",
      args,
      (shallow): DeepNullableObj<Careseeker> => ({
        __typename: "Careseeker",
        id: 2,
        address: {
          address: "Soldiner Str. 38",
          city: "Berlin",
          country: "DE",
          latitude: null,
          longitude: null,
          street_number: "38",
          street: "Soldiner Str",
          zipcode: "13359",
        },
        banking_details: {
          bank_name: "",
          iban: "",
          ik_number: "",
        },
        central_email: null,
        company_id: null,
        company: { id: 1, name: "", force_id: "", status: null },
        config: {
          activate_gateway_debug_mode: false,
          activate_parallel_search: false,
          activate_search_merge: false,
          activate_ssl_hl7_connector: false,
          auto_validate: false,
          blacklisted_assessment_fields: null,
          deactivate_received_files: null,
          disable_seald: false,
          seald_only_encryption: true,
          filter_on_social_worker: null,
          ip_range: "",
          jwt_expiration_in_minutes: null,
          on_premise_archive_integration_enabled: null,
          on_premise_authorization_token: "",
          on_premise_domain_ssl: "",
          on_premise_file_sync: false,
          seald_config: null,
          use_kis_integration: null,
          whitelisted_email_domains: [],
          whitelisted_home_care_specializations: null,
          whitelisted_hospital_specializations: null,
          whitelisted_reha_specializations: null,
        },
        contact_name: "",
        contact_phone: "",
        created_at: null,
        events: mockEvent({ ...shallow("Event") }),
        force_id: "",
        intercom_id: "",
        modules: {
          care: mockCareseekerModule({ ...shallow("CareseekerModule") }),
          hospital: mockCareseekerModule({ ...shallow("CareseekerModule") }),
          medical_supplies: mockCareseekerModule({
            ...shallow("CareseekerModule"),
          }),
          reha: mockCareseekerModule({ ...shallow("CareseekerModule") }),
          transport: mockCareseekerModule({ ...shallow("CareseekerModule") }),
        },
        name: "Demo Clinic",
        notes: "",
        number_of_beds: null,
        ppm_sender_id: null,
        on_premise_config_id: null,
        activate_on_premise: null,
        external_id: null,
        on_premise_config: {
          __typename: "OnpremiseConfig",
          activate_ssl_hl7_connector: null,
          careseekers: null,
          config: { __typename: "Config" },
          id: null,
          name: null,
          on_premise_authorization_token: null,
          on_premise_domain_ssl: null,
          on_premise_file_sync: null,
          should_restart: null,
          token: null,
        },
        patient_types: [],
        priority_providers: [
          {
            __typename: "Careprovider",
            id: 1,
            name: "",
            onboarding: { status: null },
            patient_type: null,
            status: null,
          },
        ],
        seald_encryption_context: null,
        sender_user_management_file: null,
        sso_integration: null,
        stations_full: [],
        stations: [],
        status: null,
        type: null,
        updated_at: null,
        product_features: [],
      }),
    ),
  );

export default mockCareseeker;
